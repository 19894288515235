<template>
  <div class="con-wrap">
    <CCReportAddPopup
      v-if="pop.isShow"
      @close="pop.isShow = false"
    ></CCReportAddPopup>
    <CarrotTitle title="나의 휴가관리">
      - 입사일 기준 만 1년 시 근속 연도에 해당하는 연차가 부여됩니다.<br />
      - 연차는 사전에 계획하여 체계적으로 사용합니다.<br />
      - 이월 가능한 연차는 해당 연도에 지급된 기본 연차의 1/2입니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-col">
            <colgroup>
              <col span="4" />
            </colgroup>
            <thead>
              <tr>
                <th>입사일</th>
                <th>휴가일수</th>
                <th>총 사용</th>
                <th>총 잔여</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ view.info.joindate }}</td>
                <td>{{ view.vacation.tot_vacation }}</td>
                <td>{{ view.vacation.use_vacation }}</td>
                <td>{{ view.vacation.cur_vacation }}</td>
              </tr>
            </tbody>
          </table>

          <div class="block-title mt-50 mb-20">연차현황</div>
          <table class="table-col">
            <colgroup>
              <col span="15" />
            </colgroup>
            <thead>
              <tr>
                <th colspan="3">루키</th>
                <th colspan="3">올해연차</th>
                <th colspan="3">아웃팅</th>
                <th colspan="3">전년도이월</th>
                <th colspan="3">그 외</th>
                <th colspan="3">합계</th>
              </tr>
              <tr>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>이월<br />연차</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
                <th>총지급</th>
                <th>사용</th>
                <th>잔여</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.tot < 0 }">{{
                    view.vacation.rookie.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.use < 0 }">{{
                    view.vacation.rookie.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.rookie.cur < 0 }">{{
                    view.vacation.rookie.cur
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.tot < 0 }">{{
                    view.vacation.annual.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.use < 0 }">{{
                    view.vacation.annual.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.annual.cur < 0 }">{{
                    view.vacation.annual.cur
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.tot < 0 }">{{
                    view.vacation.outing.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.use < 0 }">{{
                    view.vacation.outing.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.outing.cur < 0 }">{{
                    view.vacation.outing.cur
                  }}</span>
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.tot < 0 }"
                    >{{ view.vacation.carryover.tot }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.use < 0 }"
                    >{{ view.vacation.carryover.use }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.carryover.cur < 0 }"
                    >{{ view.vacation.carryover.cur }}</span
                  >
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.tot < 0 }">{{
                    view.vacation.etc.tot
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.use < 0 }">{{
                    view.vacation.etc.use
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.vacation.etc.cur < 0 }">{{
                    view.vacation.etc.cur
                  }}</span>
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.tot_vacation < 0 }"
                    >{{ view.vacation.tot_vacation }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.use_vacation < 0 }"
                    >{{ view.vacation.use_vacation }}</span
                  >
                </td>
                <td>
                  <span
                    :class="{ 'color-1': view.vacation.cur_vacation < 0 }"
                    >{{ view.vacation.cur_vacation }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div class="block-title mt-50 mb-20">사용연차</div>
          <table class="table-col">
            <colgroup>
              <col span="13" />
            </colgroup>
            <thead>
              <tr>
                <th>루키</th>
                <th>올해연차</th>
                <th>경조휴가</th>
                <th>산전후<br />휴가</th>
                <th>배우자<br />출산</th>
                <!-- <th>외근</th> -->
                <th>출장</th>
                <th>교육</th>
                <th>워크샵</th>
                <th>병역훈련</th>
                <th>백신휴가</th>
                <!-- <th>병가</th> -->
                <!-- <th>무급휴가</th> -->
                <th>휴일근무</th>
                <th>아웃팅</th>
                <th>합계</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="{ 'color-1': view.used.rookie < 0 }">{{
                    view.used.rookie
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.annual < 0 }">{{
                    view.used.annual
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.compassionate < 0 }">{{
                    view.used.compassionate
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.maternity < 0 }">{{
                    view.used.maternity
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.maternity2 < 0 }">{{
                    view.used.maternity2
                  }}</span>
                </td>
                <!-- <td><span :class="{ 'color-1':view.used.outside<0 }">{{ view.used.outside }}</span></td> -->
                <td>
                  <span :class="{ 'color-1': view.used.biztrip < 0 }">{{
                    view.used.biztrip
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.education < 0 }">{{
                    view.used.education
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.workshop < 0 }">{{
                    view.used.workshop
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.reservedmili < 0 }">{{
                    view.used.reservedmili
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.medicheck < 0 }">{{
                    view.used.medicheck
                  }}</span>
                </td>
                <!-- <td><span :class="{ 'color-1':view.used.sick<0 }">{{ view.used.sick }}</span></td> -->
                <!-- <td><span :class="{ 'color-1':view.used.unpaid<0 }">{{ view.used.unpaid }}</span></td> -->
                <td>
                  <span :class="{ 'color-1': view.used.comptime < 0 }">{{
                    view.used.comptime
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.outing < 0 }">{{
                    view.used.outing
                  }}</span>
                </td>
                <td>
                  <span :class="{ 'color-1': view.used.total < 0 }">{{
                    view.used.total
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="tabDefult-col dp-table w-100per mt-50 mb-20">
            <div class="float-left">
              <button
                class="tab-default"
                :class="{ on: view.type == 'detail' }"
                @click="view.viewVaTab('detail')"
              >
                상세휴가일정
              </button>
              <button
                class="tab-default"
                :class="{ on: view.type == 'plus' }"
                @click="view.viewVaTab('plus')"
              >
                추가휴가
              </button>
              <button
                class="tab-default"
                :class="{ on: view.type == 'minus' }"
                @click="view.viewVaTab('minus')"
              >
                차감휴가
              </button>
            </div>
            <div class="float-right">
              <select
                class="w-150px"
                v-model="view.year"
                @change="view.changeYear()"
              >
                <option :value="v" v-for="(v, k) in view.search_years" :key="k">
                  {{ v }}
                </option>
              </select>
            </div>
          </div>
          <table class="table-col">
            <colgroup>
              <col width="80" />
              <col width="*" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
              <col width="150" />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>휴가종류</th>
                <th>구분</th>
                <th>휴가시작</th>
                <th>휴가종료</th>
                <th>휴가일수</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in view.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>{{ irow.vtype_txt }}</td>
                <td>{{ irow.utype_txt }}</td>
                <td>{{ irow.sdate }}</td>
                <td>{{ irow.edate }}</td>
                <td>{{ irow.days }}</td>
              </tr>
              <tr v-if="view.total == 0">
                <td colspan="6">휴가 내역이 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="view.total"
            :list_per_page="view.rows"
            v-model="view.page"
            @change="view.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import axios from "@/plugins/axios.js";
import CCReportAddPopup from "@/components/popup/myPIMS/PersonalInfoList/CCReportAddPopup.vue";

export default {
  layout: "myPIMS",
  components: {
    CCReportAddPopup,
  },
  setup() {
    const router = new useRouter();
    const toast = useToast();
    const store = useStore();
    const pop = reactive({
      // Popup 노출 여부
      isShow: false,
      idx: 0,
    });

    const view = reactive({
      search_years: [],

      idx: 0,
      page: 1,
      rows: 50,
      year: "",
      type: "detail",

      info: {},
      list: [],
      total: 0,
      vacation: {
        rookie: {},
        annual: {},
        outing: {},
        carryover: {},
        etc: {},
      },
      used: {},

      goList: () => {
        router.push({
          name: "myPIMS-IncumbentManagementList",
        });
      },

      makeSearchYears: () => {
        let ey = view.info.joindate.substr(0, 4);
        let cd = new Date();
        let cy = cd.getFullYear();
        for (let i = cy; i >= ey; i--) {
          view.search_years.push(i);
        }
      },

      changeYear: () => {
        if (view.idx > 0) {
          view.page = 1;
          view.doSearch();
        }
      },

      viewTab: (id) => {
        router.push({
          name: "myPIMS-PersonalInfoList_tab" + id + "-idx",
          params: { idx: view.idx },
        });
      },

      viewVaTab: (id) => {
        view.page = 1;
        view.type = id;
        console.log(view.idx);
        if (view.idx > 0) {
          view.doSearch();
        }
      },

      doSearch: () => {
        let params = {
          idx: view.idx,
          page: view.page,
          rows: view.rows,
          year: view.year,
          type: view.type,
        };

        axios
          .get("/rest/mypims/getVacationList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              view.list = res.data.list;
              view.total = res.data.total;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      doSearchVA: () => {
        let params = {};

        axios
          .get("/rest/mypims/getMyHqInfoVA", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              view.info = res.data;
              view.vacation = res.data.vacation;
              view.used = res.data.used;

              view.idx = res.data.idx;

              view.makeSearchYears();
              view.doSearch();
            } else {
              if (res.data.err_msg) {
                console.log(
                  `조건분기 전  myVaction store.state.isToastVisible = ${store.state.isToastVisible}`
                );
                if (!store.state.isToastVisible) {
                  store.commit("setisToastVisible", true);
                  toast.error(res.data.err_msg, {
                    onClose: () => {
                      store.commit("setisToastVisible", false);
                    },
                  });
                }
              }
            }
          });
      },
    });

    onMounted(() => {
      // Mounted
      let td = new Date();
      view.year = td.getFullYear();

      view.doSearchVA();
    });

    return { pop, view };
  },
};
</script>

<style lang="scss" scoped></style>
